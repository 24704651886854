<template>
  <v-footer
    id="dashboard-core-footer"
  >
    <v-container>
      <v-row
        align="center"
        no-gutters
      >
        <!--        <v-col-->
        <!--          v-for="(link, i) in links"-->
        <!--          :key="i"-->
        <!--          class="text-center mb-sm-0 mb-5"-->
        <!--          cols="auto"-->
        <!--        >-->
        <!--          <a-->
        <!--            :href="link.href"-->
        <!--            class="mr-0 grey&#45;&#45;text text&#45;&#45;darken-3"-->
        <!--            rel="noopener"-->
        <!--            target="_blank"-->
        <!--            v-text="link.text"-->
        <!--          />-->
        <!--        </v-col>-->

        <!--        <v-spacer class="hidden-sm-and-down" />-->

        <v-col
          cols="12"
          class="d-flex align-content-center justify-center"
        >
          <div class="body-1 font-weight-light pt-md-0 text-center">
            &copy; 2020, made with
            <v-icon size="18">
              mdi-heart
            </v-icon>
            by Cobi
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      links: [

      ],
    }),
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
